import { UtmCookies } from '@/components/pages/_app/CookieConsent/UtmCookies'
import { VisitorIdContextProvider } from '@/context/visitorIdContext'
import {
  KAMELEOON_GLOBAL_VARIABLE_NAME,
  KAMELEOON_QUEUE_NAME,
  safelyCallKameleoon,
  sendExperimentViewedToGA4,
} from '@/lib/kamaleoon'
import { createClient, Environment, KameleoonProvider } from '@kameleoon/react-sdk'
import { withPasswordProtect } from '@storyofams/next-password-protect'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { ReactElement, ReactNode, useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from 'styles/globalStyles'
import { theme } from 'styles/theme'
import { CookieConsentContextProvider } from '@/context/cookieConsentContext'
import { GeoContextProvider } from '@/context/geoContext'
import { AuthContextProvider } from '@/context/authContext'
import { TrackingProvider } from '@/context/trackingContext'
import { ExperimentProvider } from '@/context/ExperimentContext'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

// Deactivated for now
if (false && process.env.NODE_ENV === 'development') {
  require('mocks')
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const client = createClient({
    siteCode: 'mjqpibgdlq',
    configuration: {
      updateInterval: 60,
      environment: process.env.NODE_ENV === 'development' ? Environment.Development : Environment.Production,
    },
  })

  useEffect(() => {
    window.addEventListener('Kameleoon::ExperimentActivated', function (event: any) {
      sendExperimentViewedToGA4(event.detail.experiment)
    })
  }, [])

  useEffect(() => {
    const kameleoonQueue = window[KAMELEOON_QUEUE_NAME]
    if (kameleoonQueue) {
      kameleoonQueue.push(['Events.trigger', 'hydrated'])
      kameleoonQueue.push(() => {
        safelyCallKameleoon(window[KAMELEOON_GLOBAL_VARIABLE_NAME]?.API?.Experiments?.getActive)?.forEach(
          sendExperimentViewedToGA4,
        )
      })
    }
  }, [])

  useEffect(() => {
    const brazeApiKey = process.env.NEXT_PUBLIC_BRAZE_API_KEY
    const brazeSdkEndpoint = process.env.BRAZE_SDK_ENDPOINT
    if (brazeApiKey && brazeSdkEndpoint) {
      import('@/lib/braze/braze-exports').then(({ initialize, openSession, automaticallyShowInAppMessages }) => {
        initialize(brazeApiKey, {
          baseUrl: brazeSdkEndpoint,
          enableLogging: process.env.NEXT_PUBLIC_ZOE_ENV !== 'PROD' && process.env.NEXT_ENABLE_BRAZE_LOGGING === 'true',
          allowUserSuppliedJavascript: true,
        })
        automaticallyShowInAppMessages()
        openSession()
      })
    }
  }, [])

  const getLayout = Component.getLayout ?? ((page) => page)
  return (
    <>
      <AuthContextProvider>
        <CookiesProvider>
          <KameleoonProvider client={client}>
            <GeoContextProvider>
              <VisitorIdContextProvider>
                <ExperimentProvider>
                  <TrackingProvider>
                    <CookieConsentContextProvider>
                      <GlobalStyles />
                      <UtmCookies />
                      <ThemeProvider theme={theme}>{getLayout(<Component {...pageProps} />)}</ThemeProvider>
                    </CookieConsentContextProvider>
                  </TrackingProvider>
                </ExperimentProvider>
              </VisitorIdContextProvider>
            </GeoContextProvider>
          </KameleoonProvider>
        </CookiesProvider>
      </AuthContextProvider>
    </>
  )
}

export default process.env.PASSWORD_PROTECTED_ENVIRONMENT
  ? withPasswordProtect(App, {
      loginComponentProps: {
        buttonBackgroundColor: '#FA7268',
        buttonColor: '#FFFFFF',
        logo: '/assets/img/zoe-logo.svg',
      },
    })
  : App
