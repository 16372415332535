import { css } from 'styled-components'

/**
 * Form field styles
 * Figma: https://www.figma.com/design/emycBlnyKjfXwW9K6pKvsh/%E2%9C%85-LBR%3A-Web-design-system?node-id=7227-1238&m=dev
 */
export const formFieldStyles = css`
  --colour-surface-input: var(--colour-surface-neutral);
  --colour-surface-input-disabled: var(--colour-surface-neutral-disabled);
  --colour-text-input: var(--blue-400);
  --colour-text-input-placeholder: var(--colour-text-placeholder);
  --colour-border-input: var(--colour-border);
  --colour-border-input-focus: var(--colour-border-focused);
  --font-size-input: var(--font-size-16);
  --line-height-input: var(--line-height-28);
  --padding-input-y: var(--grid-12);
  --padding-input-x: var(--grid-16);
`
