import { css } from 'styled-components'

/**
 * Radius
 * Figma: https://tinyurl.com/bdfmy4uz
 */
export const radius = css`
  --radius-4: 4px;
  --radius-8: 8px;
  --radius-12: 12px;
  --radius-16: 16px;
  --radius-24: 24px;
  --radius-full: 1000px;
`
