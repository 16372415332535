import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

export const UTM_COOKIE_NAMES = [
  'zoe_cam',
  'zoe_con',
  'zoe_med',
  'zoe_chan_init',
  'zoe_sou',
  'zoe_loc_init',
  'zoe_fb_id',
  'zoe_term',
  'zoe_adg',
]

export const UtmCookies = () => {
  const router = useRouter()
  const [cookie, setCookie] = useCookies(UTM_COOKIE_NAMES)

  const addCookie = (expirationDate: Date, cookieName: string, cookieValue?: string | string[]) => {
    if (!cookie[cookieName] && cookieValue) {
      setCookie(cookieName, cookieValue, {
        path: '/',
        expires: expirationDate,
      })
    }
  }

  const getCookieExpirationDate = () => {
    const today = new Date()
    today.setDate(today.getDate() + 50)
    const expirationDate = new Date(today.getTime() + 24 * 60 * 60 * 1000)

    return expirationDate
  }

  const UTMCookies = (expirationDate: Date) => {
    addCookie(expirationDate, 'zoe_cam', router.query.utm_campaign)
    addCookie(expirationDate, 'zoe_chan_init', router.query.utm_channel)
    addCookie(expirationDate, 'zoe_con', router.query.utm_content)
    addCookie(expirationDate, 'zoe_med', router.query.utm_medium)
    addCookie(expirationDate, 'zoe_sou', router.query.utm_source)
    addCookie(expirationDate, 'zoe_adg', router.query.utm_adgroup)
    addCookie(expirationDate, 'zoe_term', router.query.utm_term)
    addCookie(expirationDate, 'zoe_fb_id', router.query.fb_id)
  }

  useEffect(() => {
    const handleRouteChange = () => {
      UTMCookies(getCookieExpirationDate())
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    UTMCookies(getCookieExpirationDate())
  }, [router.isReady])

  return <></>
}
