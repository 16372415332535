import { css } from 'styled-components'

/**
 * Button Styles
 * Figma: https://www.figma.com/design/emycBlnyKjfXwW9K6pKvsh/%E2%9C%85-LBR%3A-Web-design-system?node-id=7213-1386&m=dev
 */
export const buttonStyles = css`
  // Large button
  --font-size-button-large: var(--font-size-16);
  --line-height-button-large: var(--line-height-28);
  --padding-button-large-y: var(--grid-16);
  --padding-button-large-x: var(--grid-32);
  --border-width-button-secondary: 1px;
  --border-width-button-secondary-hover: 1px;

  // Small button
  --font-size-button-small: var(--font-size-14);
  --line-height-button-small: var(--line-height-22);
  --padding-button-small-y: var(--grid-8);
  --padding-button-small-x: var(--grid-16);

  // Primary button
  --colour-surface-button-primary: var(--colour-surface-yellow-bold);
  --colour-surface-button-primary-hover: var(--yellow-500);
  --colour-text-button-primary: var(--colour-text-primary);
  --colour-text-button-primary-hover: var(--colour-text-primary);

  // Primary button inverse
  --colour-surface-button-primary-inverse: var(--colour-surface-navy-bold);
  --colour-surface-button-primary-inverse-hover: var(--blue-500);
  --colour-text-button-primary-inverse: var(--colour-text-primary-inverse);
  --colour-text-button-primary-inverse-hover: var(--colour-text-primary-inverse);

  // Secondary button
  --colour-border-button-secondary: var(--colour-text-primary);
  --colour-surface-button-secondary: var(--transparent);
  --colour-surface-button-secondary-hover: var(--colour-surface-navy-bold);
  --colour-text-button-secondary: var(--colour-text-primary);
  --colour-text-button-secondary-hover: var(--colour-text-primary-inverse);

  // Secondary button inverse
  --colour-border-button-secondary-inverse: var(--colour-border-inverse);
  --colour-surface-button-secondary-inverse: var(--transparent);
  --colour-surface-button-secondary-inverse-hover: var(--colour-surface-yellow-bold);
  --colour-text-button-secondary-inverse: var(--colour-text-primary-inverse);
  --colour-text-button-secondary-inverse-hover: var(--colour-text-primary);

  // Tertiary button
  --colour-text-button-tertiary: var(--colour-text-link);
  --colour-text-button-tertiary-hover: var(--colour-text-link-hover);
`
