import { useCallback } from 'react'
import { KAMELEOON_GLOBAL_VARIABLE_NAME, KAMELEOON_QUEUE_NAME, safelyCallKameleoon } from '@/lib/kamaleoon/index'

export const useKameleoonApi = () => {
  const processPurchaseConversion = useCallback((goal: number, revenue: number, orderProduct: string) => {
    const kameleoonQueue = window[KAMELEOON_QUEUE_NAME]
    if (kameleoonQueue) {
      kameleoonQueue.push(['Data.setCustomData', 'Purchased Product Id', orderProduct])
      kameleoonQueue.push(['Goals.processConversion', goal, revenue])
      console.log(`[Kameleoon] Goal processed: PURCHASE | Custom goal:  [Purchased Product Id, ${orderProduct}]`)
    }
  }, [])

  const processGoalConversion = useCallback((goal: number) => {
    const kameleoonQueue = window[KAMELEOON_QUEUE_NAME]
    if (kameleoonQueue) {
      kameleoonQueue.push(['Goals.processConversion', goal])
    }
  }, [])

  const waitForAssociatedVariation = useCallback(async (experimentName: string, maxAttempts = 10, interval = 50) => {
    for (let i = 0; i < maxAttempts; i++) {
      const kameleoon = window[KAMELEOON_GLOBAL_VARIABLE_NAME]
      if (kameleoon?.API?.CurrentVisit) {
        const experiment = safelyCallKameleoon(kameleoon?.API?.Experiments?.getByName, experimentName)
        if (!experiment) return null
        const associatedVariation = experiment?.associatedVariation
        if (associatedVariation?.id !== null) {
          return associatedVariation
        }
      }
      await new Promise((resolve) => setTimeout(resolve, interval))
    }
    return null
  }, [])

  const setCountryCustomData = useCallback((country: string) => {
    const kameleoonQueue = window[KAMELEOON_QUEUE_NAME]
    if (kameleoonQueue) {
      kameleoonQueue.push(['Data.setCustomData', 'Country', country])
    }
  }, [])

  return {
    processGoalConversion,
    processPurchaseConversion,
    waitForAssociatedVariation,
    setCountryCustomData,
  }
}
