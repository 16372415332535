import React, { createContext, useState } from 'react'
import { User } from 'firebase/auth'
import { CustomerDetails } from '../clients/stripe-gateway'

export interface LoggedInCustomerDetails extends CustomerDetails {
  hasActiveZoeMembership: boolean
  hasActiveDaily30Membership: boolean
}

export type LoggedInCustomerDetailsStateValues = 'NOT_SET' | 'LOADED' | 'UNEXPECTED_ERROR' | 'NOT_FOUND'

export interface LoggedInCustomer {
  details: LoggedInCustomerDetails | undefined
  state: LoggedInCustomerDetailsStateValues
}

export const loggedInCustomerInitialState: LoggedInCustomer = {
  details: undefined,
  state: 'NOT_SET',
}

interface IAuthContext {
  user: User | null
  setUser: (user: User | null) => void
  loggedInCustomer: LoggedInCustomer
  setLoggedInCustomer: (state: LoggedInCustomer) => void
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
  loggedInCustomer: loggedInCustomerInitialState,
  setLoggedInCustomer: () => {},
})

export interface AuthContextProviderProps {
  children: React.ReactNode
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [user, setUser] = React.useState<User | null>(null)
  const [loggedInCustomer, setLoggedInCustomer] = useState<LoggedInCustomer>(loggedInCustomerInitialState)

  return (
    <AuthContext.Provider value={{ user, setUser, loggedInCustomer, setLoggedInCustomer }}>
      {children}
    </AuthContext.Provider>
  )
}
