import { createContext, ReactNode, useCallback, useState } from 'react'
import { Section } from '@/lib/gtm/typesV2'
import { trackAccordionClickEvent } from '@/lib/gtm/gtmV2'

type TrackingContextType = {
  section: Section | null
  setSection: (section: Section | null) => void
  trackAccordionClick: (textOrImageUrl: string) => void
}

export const TrackingContext = createContext<TrackingContextType>({
  section: null,
  setSection: () => {},
  trackAccordionClick: () => {},
})

export const TrackingProvider = (props: { children: ReactNode }) => {
  const [section, setSection] = useState<Section | null>(null)

  const trackAccordionClick = useCallback(
    (textOrImageUrl: string) => {
      trackAccordionClickEvent({ section: section || 'cms', textOrImageUrl })
    },
    [section],
  )

  return (
    <TrackingContext.Provider value={{ section, setSection, trackAccordionClick }}>
      {props.children}
    </TrackingContext.Provider>
  )
}
