import { css } from 'styled-components'

/**
 * Grid (Spacing)
 * Figma: https://tinyurl.com/4r9xen2x
 */
export const gridSpacing = css`
  --grid-2: 0.125rem; // 2px
  --grid-4: 0.25rem; // 4px
  --grid-8: 0.5rem; // 8px
  --grid-12: 0.75rem; // 12px
  --grid-16: 1rem; // 16px
  --grid-24: 1.5rem; // 24px
  --grid-32: 2rem; // 32px
  --grid-40: 2.5rem; // 40px
  --grid-48: 3rem; // 48px
  --grid-72: 4.5rem; // 72px
  --grid-96: 6rem; // 96px
  --grid-128: 8rem; // 128px
  --grid-144: 9rem; // 144px
`

export type DSGridSpacing = 0 | 2 | 4 | 8 | 12 | 16 | 24 | 32 | 40 | 48 | 72 | 96 | 128 | 144
