import * as Sentry from '@sentry/nextjs'

export type KameleoonExperiment = {
  name: string
  id: string
  associatedVariation: {
    name: string
    id: string
  }
}
export const GOALS = {
  REACHED_WHATS_INCLUDED_PAGE: 335803,
  REACHED_CHOOSE_PLAN_PAGE: 335804,
  PURCHASE: 336432,
  DAILY_30_PURCHASE: 342038,
  REACHED_CHECKOUT_PAGE: 347562,
  HOMEPAGE_EMAIL_SUBMITTED: 354425,
}

export const KAMELEOON_GLOBAL_VARIABLE_NAME = 'Kameleoon'
export const KAMELEOON_QUEUE_NAME = 'kameleoonQueue'

export function sendExperimentViewedToGA4(experiment: KameleoonExperiment) {
  const zoeDataLayer = (window as any).zoeDataLayer || []
  const eventAlreadyExists = zoeDataLayer.some(
    (event: any) =>
      event.event === 'experiment_viewed' &&
      event.experiment_id === experiment.name &&
      event.variant_id === experiment.associatedVariation.name,
  )

  if (!eventAlreadyExists) {
    zoeDataLayer.push({
      event: 'experiment_viewed',
      experiment_id: experiment.name,
      variant_id: experiment.associatedVariation.name,
    })
  }
}

export const safelyCallKameleoon = (method: Function, ...args: any[]) => {
  if (typeof method === 'function') {
    try {
      return method(...args)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}
