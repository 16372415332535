import { createContext, ReactNode, useState } from 'react'

type CookieContextType = {
  cookieConsent: boolean
  setCookieConsent: (cookieConsent: boolean) => void
}

type Props = {
  children: ReactNode
}

export const CookieConsentContext = createContext<CookieContextType>({
  cookieConsent: false,
  setCookieConsent: (_) => {},
})

export const CookieConsentContextProvider = (props: Props) => {
  const [cookieConsent, setCookieConsent] = useState(false)

  return (
    <CookieConsentContext.Provider value={{ cookieConsent: cookieConsent, setCookieConsent: setCookieConsent }}>
      {props.children}
    </CookieConsentContext.Provider>
  )
}
