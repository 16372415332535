// Cannot define as CSS variables since they are not supported in media queries at this moment in time
export const BREAKPOINTS = {
  s: '375px',
  m: '768px',
  l: '1200px',
  xl: '1536px',
}

export type Breakpoint = keyof typeof BREAKPOINTS

export const convertPxToNumber = (value: string) => parseInt(value.replace('px', ''), 10)

export const getBreakpointForWidth = (width: number): Breakpoint => {
  if (width < convertPxToNumber(BREAKPOINTS.m)) {
    return 's'
  }
  if (width < convertPxToNumber(BREAKPOINTS.l)) {
    return 'm'
  }
  if (width < convertPxToNumber(BREAKPOINTS.xl)) {
    return 'l'
  }
  return 'xl'
}
