import { SESSION_COOKIE_CONFIG } from '@/lib/utils/cookies'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'

export const ZOE_LEAD_TRACKING_ID_COOKIE_NAME = 'cZoeLead'
export const ZOE_LEAD_TRACKING_SESSION_COOKIE_NAME = 'cZoeLeadSession'

const SECONDS_IN_A_DAY = 86400
const FOUR_YEARS_IN_SECONDS = (4 * 365 + 1) * SECONDS_IN_A_DAY

const DEFAULT_COOKIE_OPTIONS = {
  path: '/',
  maxAge: FOUR_YEARS_IN_SECONDS,
}

type ZoeLeadContextType = {
  zoeLeadId: string
  zoeSessionId: string
}

export const VisitorIdContext = createContext<ZoeLeadContextType>({
  zoeLeadId: '',
  zoeSessionId: '',
})

type Props = {
  children: ReactNode
}

export const VisitorIdContextProvider = ({ children }: Props) => {
  const [cookies, setCookie] = useCookies([ZOE_LEAD_TRACKING_ID_COOKIE_NAME, ZOE_LEAD_TRACKING_SESSION_COOKIE_NAME])

  // Key identifiers
  const [leadId] = useState<string>(cookies[ZOE_LEAD_TRACKING_ID_COOKIE_NAME] || uuidv4())
  const [sessionId] = useState<string>(cookies[ZOE_LEAD_TRACKING_SESSION_COOKIE_NAME] || uuidv4())

  // Setup useEffects
  // Save lead id cookie
  useEffect(() => {
    if (leadId && leadId !== cookies[ZOE_LEAD_TRACKING_ID_COOKIE_NAME]) {
      setCookie(ZOE_LEAD_TRACKING_ID_COOKIE_NAME, leadId, {
        ...DEFAULT_COOKIE_OPTIONS,
        domain: process.env.COOKIE_DOMAIN,
      })
    }
  }, [leadId, cookies.cZoeLead])

  // Save session id in a session cookie
  useEffect(() => {
    if (sessionId && sessionId !== cookies.cZoeLeadSession) {
      setCookie(ZOE_LEAD_TRACKING_SESSION_COOKIE_NAME, sessionId, SESSION_COOKIE_CONFIG)
    }
  }, [sessionId, cookies.cZoeLeadSession])

  return (
    <VisitorIdContext.Provider value={{ zoeLeadId: leadId, zoeSessionId: sessionId }}>
      {children}
    </VisitorIdContext.Provider>
  )
}
